import "iconify-icon";
import Swiper, {
    Autoplay,
    Navigation,
    Pagination,
    Mousewheel,
    Keyboard,
    Thumbs,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AOS from "aos";
import "aos/dist/aos.css";
import "flowbite";
import Swal from "sweetalert2";
import $ from "jquery";
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import "./../../../node_modules/jquery-mask-plugin/dist/jquery.mask.min";

function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        $("#ajax-loader").removeClass("invisible").addClass("visible");

        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function animatedCounter(targer, time = 1000, start = 0) {
    return {
        current: 0,
        target: targer,
        time: time,
        start: start,
        updatecounter: function () {
            start = this.start;
            const increment = (this.target - start) / this.time;
            const handle = setInterval(() => {
                if (this.current < this.target) this.current += increment;
                else {
                    clearInterval(handle);
                    this.current = this.target;
                }
            }, 1);
        },
    };
}

$(function () {
    $("#search-focus").on("click", function () {
        $("#search-input").focus();
    });

    $(".phone").mask("(500) 000 00 00");

    $(document).mouseup(function (e) {
        if ($(e.target).closest("#listing").length === 0) {
            $("#listing").addClass("hidden");
            $("#ajax-loader").addClass("invisible").removeClass("visible");
            $("#search-flex")
                .addClass("rounded-3xl")
                .removeClass("rounded-t-3xl");
        }
    });

    $("input#search-input").on("click", function () {
        var val = $(this).val();
        if (val.length > 3) {
            $("#ajax-loader").addClass("visible").removeClass("invisible");
            $("#search-flex")
                .removeClass("rounded-3xl")
                .addClass("rounded-t-3xl");
            $("#listing").removeClass("hidden");
            var href = $("#search-all-btn");

            $.ajax({
                url: "/ajax-search",
                type: "post",
                data: { q: val, language: $("input.language").val() },
                headers: {
                    "X-CSRF-TOKEN": $('input[name="_token"]').val(),
                },
                success: function (res) {
                    var html = "";
                    var href_data =
                        href.attr("href").split("?")[0] + "?q=" + val;
                    href.attr("href", href_data);
                    var html2 = "";
                    if (res.image.length) {
                        $.each(res.image, function (k, i) {
                            html2 +=
                                '<a href="' +
                                i.category_language.taxonomy +
                                '"><img class="h-[120px] sm:h-[160px] w-full  rounded-lg shadow"\n' +
                                'src="' +
                                i.image +
                                '" /></a>';
                        });
                        $("#img-list").html(html2);
                    }

                    if (res.data.length > 0) {
                        $.each(res.data, function (k, i) {
                            html +=
                                '<a href="' +
                                i.category_language.taxonomy +
                                '">' +
                                i.category_language.name +
                                "</a>";
                        });
                        $("#results").html(html);
                    } else {
                        $("#results").html(
                            "<li class='px-4 py-4'>Sonuç bulunamadı!</li>"
                        );
                    }
                    $("#ajax-loader")
                        .addClass("invisible")
                        .removeClass("visible");
                },
                error: function (err) {
                    console.log(err);
                },
            });
        } else {
            $("#ajax-loader").addClass("invisible").removeClass("visible");
        }
    });

    $("input#search-input").on(
        "keyup",
        debounce(function () {
            var val = $(this).val();
            if (val.length > 3) {
                $("#search-flex")
                    .removeClass("rounded-3xl")
                    .addClass("rounded-t-3xl");
                $("#listing").removeClass("hidden");
                $("#search-q").html('"' + $(this).val() + '"');
                var href = $("#search-all-btn");

                $.ajax({
                    url: "/ajax-search",
                    type: "post",
                    data: { q: val, language: $("input.language").val() },
                    headers: {
                        "X-CSRF-TOKEN": $('input[name="_token"]').val(),
                    },
                    success: function (res) {
                        var href_data =
                            href.attr("href").split("?")[0] + "?q=" + val;
                        href.attr("href", href_data);
                        var html = "";
                        var html2 = "";

                        if (res.image.length) {
                            $.each(res.image, function (k, i) {
                                html2 +=
                                    '<a href="' +
                                    i.category_language.taxonomy +
                                    '"><img class="h-[120px] sm:h-[160px] w-full  rounded-lg shadow"\n' +
                                    'src="' +
                                    i.image +
                                    '" /></a>';
                            });
                            $("#img-list").html(html2);
                        }

                        if (res.data.length > 0) {
                            $.each(res.data, function (k, i) {
                                html +=
                                    '<a href="' +
                                    i.category_language.taxonomy +
                                    '">' +
                                    i.category_language.name +
                                    "</a>";
                            });

                            $("#results").html(html);
                        } else {
                            $("#results").html(
                                "<li class='px-4 py-4'>Sonuç bulunamadı!</li>"
                            );
                        }
                        $("#search-count").html(res.count);
                        $("#ajax-loader")
                            .addClass("invisible")
                            .removeClass("visible");
                    },
                    error: function (err) {
                        console.log(err);
                    },
                });
            } else {
                $("#ajax-loader").addClass("invisible").removeClass("visible");
            }
        }, 500)
    );

    $("#form-ac").on("click", function () {
        $("#test").removeClass("hidden");
    });

    $("#form-kapat").on("click", function () {
        $("#test").addClass("hidden");
    });
    $("form#help-form").on("submit", function () {
        $.ajax({
            url: "/nasil-form",
            type: "post",
            data: $(this).serialize(),
            headers: {
                "X-CSRF-TOKEN": $('input[name="_token"]').val(),
            },
            success: function (res) {
                $("#formErrors2").addClass("hidden");
                $("#formSuccess2").removeClass("hidden");
                $("form#help-form").trigger("reset");
            },
            error: function (err) {
                $("#formSuccess2").addClass("hidden");
                $("#formErrors2")
                    .removeClass("hidden")
                    .find("#errors")
                    .html(err.responseJSON.message);
            },
        });
        return false;
    });

    $("form#ik-form").on("submit", function (e) {
        e.preventDefault();
        var MAX_FILE_SIZE = 11 * 1024 * 1024;

        var file = document.getElementById("file").files[0];
        if (file.size > MAX_FILE_SIZE) {
            $(".ik-form-error")
                .removeClass("hidden")
                .find("#errors")
                .html("Dosya boyutu 10MB'dan büyük olamaz.");
            return false;
        }
        $.ajax({
            url: "/ik-form",
            type: "post",
            data: new FormData(this),
            processData: false,
            contentType: false,
            enctype: "multipart/form-data",
            headers: {
                "X-CSRF-TOKEN": $('input[name="_token"]').val(),
            },
            success: function (res) {
                $(".ik-form-error").addClass("hidden");
                $(".ik-form-success").removeClass("hidden");
                $("form#ik-form").trigger("reset");
            },
            error: function (err) {
                $(".ik-form-error")
                    .removeClass("hidden")
                    .find("#errors")
                    .html(guvenlik);
            },
        });
        return false;
    });
    $("form#form").on("submit", function () {
        $.ajax({
            url: "/vmindliolun-form",
            type: "post",
            data: $(this).serialize(),
            headers: {
                "X-CSRF-TOKEN": $('input[name="_token"]').val(),
            },
            success: function (res) {
                $(".vmind-success").removeClass("hidden");
                $("div.vmind-error").addClass("hidden");
                $("form#ik-form").trigger("reset");
            },
            error: function (err) {
                $(".vmind-success").addClass("hidden");
                $("div.vmind-error")
                    .removeClass("hidden")
                    .find("#errors")
                    .html(err.responseJSON.message);
            },
        });
        return false;
    });

    $("form#isortagi-form").on("submit", function () {
        $.ajax({
            url: "/isortagi-form",
            type: "post",
            data: $(this).serialize(),
            headers: {
                "X-CSRF-TOKEN": $('input[name="_token"]').val(),
            },
            success: function (res) {
                $(".isortagi-success").removeClass("hidden");
                $(".isortagi-error").addClass("hidden");
                $("form#isortagi-form").trigger("reset");
            },
            error: function (err) {
                $(".isortagi-error")
                    .removeClass("hidden")
                    .find("#errors")
                    .html(guvenlik);
            },
        });
        return false;
    });

    $("form#rehber-form").on("submit", function () {
        $.ajax({
            url: "/rehber-form",
            type: "post",
            data: $(this).serialize(),
            headers: {
                "X-CSRF-TOKEN": $('input[name="_token"]').val(),
            },
            success: function (res) {
                $(".rehber-success").removeClass("hidden");
                $("form#rehber-form").trigger("reset");
            },
            error: function (err) {
                $(".rehber-error")
                    .removeClass("hidden")
                    .find("#errors")
                    .html(guvenlik);
            },
        });
        return false;
    });

    $("form#form-arastirma").on("submit", function () {
        $.ajax({
            url: "/arastirma-form",
            type: "post",
            data: $(this).serialize(),
            headers: {
                "X-CSRF-TOKEN": $('input[name="_token"]').val(),
            },
            success: function (res) {
                $(".arastirma-form-success").removeClass("hidden");
                $("form#form-arastirma").trigger("reset");
            },
            error: function (err) {
                $(".arastirma-form-error")
                    .removeClass("hidden")
                    .find("#errors")
                    .html(guvenlik);
            },
        });
        return false;
    });

    $("form#download-form").on("submit", function () {
        $.ajax({
            url: "/download-form",
            type: "post",
            data: $(this).serialize(),
            headers: {
                "X-CSRF-TOKEN": $('input[name="_token"]').val(),
            },
            success: function (res) {
                $("#formSuccess").removeClass("hidden");
                $("form#download-form").trigger("reset");
            },
            error: function (err) {
                $("div#formErrors")
                    .removeClass("hidden")
                    .find("#errors")
                    .html(guvenlik);
            },
        });
        return false;
    });
});

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function pageLoad() {
    // if ($(".preloader").length > 0) {
    //     var span = "we handle it.";
    //     var span = span.split("");
    //     if (getCookie("loader")) {
    //         $(".preloader").addClass("hidden");
    //     } else {
    //         $(".preloader").removeClass("hidden");
    //         setCookie("loader", "loader", 7);
    //         for (var i = 0; i < span.length; ++i) {
    //             doSetTimeout(i);
    //         }
    //         setTimeout(function () {
    //             $(".preloader").addClass("hidden");
    //         }, 2000);
    //         function doSetTimeout(i) {
    //             var item = "";
    //             if (span[i] == " ") {
    //                 item += '<span class="space"></span>';
    //             } else {
    //                 item += '<span class="scale">' + span[i] + "</span>";
    //             }
    //             setTimeout(function () {
    //                 $(".preloader .text").append(item);
    //             }, i * 60);
    //         }
    //     }
    // }
}

pageLoad();

function pageLoad2() {
    // if ($(".preloader2").length > 0) {
    //     var span = "we handle it.";
    //     var span = span.split("");
    //     $(".preloader2").removeClass("hidden");
    //     for (var i = 0; i < span.length; ++i) {
    //         doSetTimeout(i);
    //     }
    //     setTimeout(function () {
    //         $(".preloader2").addClass("hidden");
    //     }, 2000);
    //     function doSetTimeout(i) {
    //         var item = "";
    //         if (span[i] == " ") {
    //             item += '<span class="space"></span>';
    //         } else {
    //             item += '<span class="scale">' + span[i] + "</span>";
    //         }
    //         setTimeout(function () {
    //             $(".preloader2 .text").append(item);
    //         }, i * 60);
    //     }
    // }
}

pageLoad();
pageLoad2();

$(window).scroll(function () {
    if (
        $(window).scrollTop() + $(window).height() >
        $(document).height() - 300
    ) {
        var iHeight = $(".footer").offset().top;
        var iScrollHeight =
            $(".footer").offset().top + $(".footer")[0].scrollHeight;
        var sonuc =
            400 -
            ($(document).height() -
                ($(window).scrollTop() + $(window).height()));
        $("#sticky").stop(true, true).css({
            position: "fixed",
            bottom: sonuc,
        });
    } else {
        $("#sticky").stop(true, true).css({
            bottom: "100px",
        });
    }
});

const pageProducts = new Swiper(".page-products", {
    modules: [Navigation, Pagination],
    spaceBetween: 10,
    slidesPerView: 3,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        // dynamicBullets: true,
        // dynamicMainBullets: 5,
    },
    navigation: {
        nextEl: ".next-product",
        prevEl: ".prev-product",
    },
    breakpoints: {
        0: {
            gap: 10,
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 30,
        },
        480: {
            gap: 10,
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 30,
        },
        768: {
            gap: 10,
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 30,
        },
        992: {
            gap: 10,
            slidesPerGroup: 3,
            slidesPerView: 3,
        },
    },
});
// const homeTwoSwiper = new Swiper(".home-two-swiper", {
//   modules: [Navigation],
//   loop: true,
//   autoplay: {
//     delay: 2000,
//     slidesPerView: 1,
//     disableOnInteraction: false,
//   },
//   loopedSlides: 1,
//   initialSlide:5,
//   mousewheel:false,
//   slidesPerGroup: 1,
//   navigation: {
//     nextEl: ".swiper-button-next home-two-next",
//     prevEl: ".swiper-button-prev home-two-prev",
//   },
//   breakpoints: {
//     0: {
//       slidesPerView: 1,
//       spaceBetween: 10,
//     },
//     501: {
//       slidesPerView: "auto",
//     },
//   },
//   onSlideChangeEnd:function(e){
//     homeTwoSwiper.update(true);
//   }
// });

var galleryThumbs = new Swiper(".gallery-thumbs", {
    modules: [Navigation, Mousewheel],
    direction: "horizontal",
    spaceBetween: 0,
    slidesPerView: 1,
    mousewheel: true,
    navigation: {
        nextEl: ".home-two-next",
        prevEl: ".home-two-prev",
    },
    breakpoints: {
        480: {
            direction: "horizontal",
            slidesPerView: 1,
        },
    },
});
var galleryTop = new Swiper(".gallery-top", {
    modules: [Autoplay, Thumbs],
    direction: "horizontal",
    spaceBetween: 0,
    mousewheel: true,
    slidesPerView: 7,
    autoplay: {
        delay: 2000,
        slidesPerView: 1,
        disableOnInteraction: false,
    },
    thumbs: {
        swiper: galleryThumbs,
    },
});
galleryTop.on("slideChangeTransitionStart", function () {
    galleryThumbs.slideTo(galleryTop.activeIndex);
});
galleryThumbs.on("transitionStart", function () {
    galleryTop.slideTo(galleryThumbs.activeIndex);
});

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();
AOS.init();

Swiper.use([Navigation, Pagination, Autoplay]);
$(function () {
    $(".firstSlide").css({
        width: $(".home-two-swiper .swiper-slide.swiper-slide-active").width(),
    });

    $(".a-map").on("click", function () {
        var key = $(this).data("key");
        $(".maps").removeClass("!block");
        $("div#" + key).addClass("!block");

        $("html").animate(
            {
                scrollTop: $("div#" + key).offset().top,
            },
            800 //speed
        );

        return false;
    });
});

$("form#news-form").submit(function () {
    $.post($(this).attr("action"), $(this).serialize())
        .done(function (res) {
            Swal.fire({
                icon: "success",
                title: "Tebrikler!",
                text: res.message,
            });
        })
        .fail(function (xhr, status, error) {
            var obj = JSON.parse(xhr.responseText);
            obj = obj.errors;
            var errors = "";
            $.each(obj, function (key, val) {
                errors += " - " + val + "\n";
            });
            Swal.fire({
                icon: "error",
                title: "Hata!",
                text: errors,
            });
        });
    return false;
});

if ($(".hizmetler-swiper").length > 0) {
    const hizmetler = new Swiper(".hizmetler-swiper", {
        modules: [Navigation, Pagination],
        spaceBetween: 10,
        slidesPerView: 3,
        loop: true,
        pagination: false,
        navigation: {
            nextEl: ".next-hizmetler",
            prevEl: ".prev-hizmetler",
        },
        breakpoints: {
            0: {
                gap: 10,
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 30,
            },
            480: {
                gap: 10,
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 30,
            },
            768: {
                gap: 10,
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 30,
            },
            992: {
                gap: 10,
                slidesPerGroup: 3,
                slidesPerView: 3,
            },
        },
    });
}
if ($(".home-news-swiper").length > 0) {
    const homeNewsSwiper = new Swiper(".home-news-swiper", {
        modules: [Navigation, Pagination],
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".news-next",
            prevEl: ".news-prev",
        },
        breakpoints: {
            0: {
                loop: true,
                slidesPerGroup: 2,
                slidesPerView: 1,
                spaceBetween: 30,
            },
            480: {
                loop: true,
                slidesPerGroup: 3,
                slidesPerView: 3,
                spaceBetween: 30,
            },
            768: {
                loop: true,
                slidesPerGroup: 2,
                slidesPerView: 2,
                spaceBetween: 30,
            },
            992: {
                loop: true,
                slidesPerGroup: 3,
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    });
}
if ($(".home-one-list-swiper").length > 0) {
    const homeOneListSwiper = new Swiper(".home-one-list-swiper", {
        modules: [Navigation],
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        navigation: {
            nextEl: ".home-next",
            prevEl: ".home-prev",
        },
    });
}
if ($(".home-swiper").length > 0) {
    const homeSwiper = new Swiper(".home-swiper", {
        modules: [Pagination, Autoplay],
        loop: true,
        autoplay: {
            delay: 3000,
        },
        direction: "vertical",
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });
}
if ($(".home-tree-swiper").length > 0) {
    const homeTreeSwiper = new Swiper(".home-tree-swiper", {
        modules: [Navigation],
        slidesPerView: 5,
        loop: true,
        spaceBetween: 30,
        navigation: {
            nextEl: ".tree-next",
            prevEl: ".tree-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            480: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 4,
            },
            992: {
                slidesPerView: 5,
            },
        },
    });
}
if ($(".home-ref-swiper").length > 0) {
    const homeRefSwiper = new Swiper(".home-ref-swiper", {
        modules: [Navigation, Pagination],
        spaceBetween: 10,
        slidesPerView: 6,
        loop: true,
        initialSlide: 3,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
            // dynamicMainBullets: 5,
        },
        navigation: {
            nextEl: ".next-ref",
            prevEl: ".prev-ref",
        },
        breakpoints: {
            0: {
                gap: 5,
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 30,
            },
            480: {
                gap: 5,
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 30,
            },
            768: {
                gap: 5,
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 30,
            },
            992: {
                gap: 5,
                slidesPerGroup: 6,
                slidesPerView: 6,
            },
        },
    });
}
if ($(".home-our-services").length > 0) {
    const homeOurServices = new Swiper(".home-our-services", {
        modules: [Navigation, Pagination],
        loop: true,
        spaceBetween: 10,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            480: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 4,
            },
        },
    });
}
if ($(".homecertificas").length > 0) {
    const homecertificas = new Swiper(".homecertificas", {
        modules: [Navigation, Pagination],
        loop: true,
        spaceBetween: 10,
        navigation: {
            nextEl: ".next-certifica",
            prevEl: ".prev-certifica",
        },
        breakpoints: {
            0: {
                slidesPerView: 2,
            },
            480: {
                slidesPerView: 4,
            },
            768: {
                slidesPerView: 6,
            },
            992: {
                slidesPerView: 8,
            },
        },
    });
}
if ($(".home-tab-swiper0").length > 0) {
    const homeTabSwiper0 = new Swiper(".home-tab-swiper0", {
        modules: [Navigation, Pagination],
        loop: true,
        pagination: {
            el: ".page0",
            clickable: true,
        },
        navigation: {
            nextEl: ".next0",
            prevEl: ".prev0",
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 10,
                slidesPerGroup: 1,
                loop: true,
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 10,
                slidesPerGroup: 2,
                loop: true,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
                slidesPerGroup: 2,
                loop: true,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 10,
                slidesPerGroup: 3,
                loop: true,
            },
        },
    });
    homeTabSwiper0.slideTo(0);
}
if ($(".home-tab-swiper1").length > 0) {
    const homeTabSwiper1 = new Swiper(".home-tab-swiper1", {
        modules: [Navigation, Pagination],
        slidesPerGroup: 3,
        loop: true,
        pagination: {
            el: ".page1",
            clickable: true,
        },
        navigation: {
            nextEl: ".next1",
            prevEl: ".prev1",
        },
        // on: {
        //   afterInit: ($event) => {
        //     this.slideTo(0);
        //   },
        // },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
        },
    });
    homeTabSwiper1.slideTo(0);
}
if ($(".home-tab-swiper2").length > 0) {
    const homeTabSwiper2 = new Swiper(".home-tab-swiper2", {
        modules: [Navigation, Pagination],
        slidesPerGroup: 3,
        loop: true,
        pagination: {
            el: ".page2",
            clickable: true,
        },
        navigation: {
            nextEl: ".next2",
            prevEl: ".prev2",
        },

        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 10,
                slidesPerGroup: 1,
                loop: true,
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 10,
                slidesPerGroup: 2,
                loop: true,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
                slidesPerGroup: 2,
                loop: true,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 10,
                slidesPerGroup: 3,
                loop: true,
            },
        },
    });
    homeTabSwiper2.slideTo(0);
}
// if($('.home-tab-swiper0').length > 0){
// homeTabSwiper0.slideTo(0);
// }
// if($('.home-tab-swiper2').length > 0){
// homeTabSwiper2.slideTo(0);
// }
// if($('.home-tab-swiper1').length > 0){
// homeTabSwiper1.slideTo(0);
// }

const product = new Swiper(".home-product-swiper", {
    modules: [Navigation, Pagination],
    initialSlide: 4,
    pagination: {
        el: ".page-product",
        dynamicBullets: true,
        dynamicMainBullets: true,
        clickable: true,
    },
    navigation: {
        nextEl: ".next-product-home",
        prevEl: ".prev-product-home",
    },

    breakpoints: {
        0: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
            loop: true,
        },
        480: {
            slidesPerView: 2,
            spaceBetween: 10,
            slidesPerGroup: 1,
            loop: true,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
            loop: true,
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 10,
            slidesPerGroup: 1,
            loop: true,
        },
    },
});
//
// const lazyImages = document.querySelectorAll(".lazy");
//
// const options = {
//   root: null, // Use the viewport as the root
//   rootMargin: "0px",
//   threshold: 0.1 // Specify the threshold for intersection
// };

// const handleIntersection = (entries, observer) => {
//   entries.forEach((entry) => {
//     console.log(entry);
//     if (entry.isIntersecting) {
//       const img = entry.target;
//       const src = img.getAttribute("data-src");
//       // Replace the placeholder with the actual image source
//       img.src = src;
//       // Stop observing the image
//       observer.unobserve(img);
//     }
//   });
// };
//
// const observer = new IntersectionObserver(handleIntersection, options);
//
// lazyImages.forEach((image) => {
//   observer.observe(image);
// });

$(window).scroll(startCounter);

function startCounter() {
    if ($(window).scrollTop() > 400) {
        $(window).off("scroll", startCounter);
        $(".Count").each(function () {
            var $this = $(this);
            $({
                Counter: 0,
                uptime: false,
            }).animate(
                {
                    Counter: $this.data("value"),
                    uptime: $this.data("value") === 99.99,
                },
                {
                    duration: $this.data("duration"),
                    easing: "swing",
                    step: function () {
                        if (this.uptime) {
                            $this.text(
                                parseFloat(this.Counter).toFixed(2) +
                                    $this.data("ek")
                            );
                        } else {
                            $this.text(
                                Math.ceil(this.Counter) + " " + $this.data("ek")
                            );
                        }
                    },
                }
            );
        });
    }
}

// import { Modal } from 'flowbite';
// const modalElement = document.querySelector('#demo-modal');
// const modal = new Modal(modalElement);

window.onload = function () {
    setTimeout(() => {
        $("#ajax-loading").hide();
        $("#not-loading").removeClass("hidden");
        $(".not-loading").removeClass("hidden");
    }, 400);
};
